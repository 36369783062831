<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-form :inline="true">
      <el-form-item label="渠道">
        <el-select v-model="source" clearable>
          <el-option label="小程序" value="sport"></el-option>
          <el-option label="安卓" value="adnroid"></el-option>
          <el-option label="ios" value="ios"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付类型">
        <el-select v-model="pay_type" @change="payTypeChange" clearable>
          <el-option label="微信" value="wx"></el-option>
          <el-option label="支付宝" value="ali"></el-option>
          <el-option label="抖音" value="dy"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账单类型">
        <el-select v-model="bill_type" clearable>
          <el-option
            v-for="(item, index) in bill_type_list"
            :label="item.value"
            :value="item.name"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-form :inline="true">
      <el-form-item label="账单时间">
        <el-radio v-model="time_mode" label="day">日账单</el-radio>
        <el-radio v-model="time_mode" label="month" v-if="pay_type != 'wx'"
          >月账单</el-radio
        >
      </el-form-item>

      <el-form-item label="选择日期" v-if="time_mode == 'day'">
        <el-date-picker
          clearable
          v-model="dayTime"
          value-format="timestamp"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="选择月"
        v-if="time_mode == 'month' && pay_type != 'wx'"
      >
        <el-date-picker
          clearable
          v-model="monthTime"
          value-format="timestamp"
          type="month"
          placeholder="选择月"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
      </el-form-item>
    </el-form>
    <el-button v-if="this.list.length>0" style="margin-left: 30px" type="primary" @click="ganareExcel"
      >全部导出</el-button
    >
    <el-table
      ref="multipleTable"
      :data="list.slice(0,10)"
      style="width: 100%"
      tooltip-effect="dark"
    >
      <el-table-column prop="businessName" label="商品名称"> </el-table-column>
      <el-table-column prop="totalAmount" label="用户手机号"> </el-table-column>
      <el-table-column prop="refundAmount" label="总金额"> </el-table-column>
      <el-table-column prop="userIdentity" label="退款金额"> </el-table-column>
      <el-table-column prop="transStatus" label="交易状态"> </el-table-column>
      <el-table-column prop="transDate" label="交易时间"> </el-table-column>
    </el-table>
  </div>
</template>

    <script>
import XLSX from "xlsx";
export default {
  /** 账单类型
   * 微信(ALL:返回当日所有订单信息, 默认值SUCCESS:返回当日成功支付的订单, REFUND:返回当日退款订单)
   * 支付宝(trade:商户基于支付宝交易收单的业务账单, signcustomer 基于商户支付宝余额收入及支出等资金变动的账务账单, merchant_act 营销活动账单，包含营销活动的发放，核销记录, trade_zft_merchant 直付通二级商户查询交易的业务账单；zft_acc：直付通平台商查询二级商户流水使用，返回所有二级商户流水)
   * 抖音(payment:支付账单, settle:分账账单, refund:退款账单, return:退分账账单, withdraw:提现账单, rebate:返佣账单, annual_rebate:年框返佣账单)
   **/

  //对账单日期(微信:日账单yyyyMMdd,支付宝:日账单yyyy-MM-dd月账单yyyy-MM,抖音:日账单yyyyMMdd月账单yyyyMM)
  data() {
    return {
      time:'',
      dayTime: "",
      monthTime: "",
      list: [],
      time_mode: "day",
      nav: { firstNav: "财务管理", secondNav: "财务账单" },
      user_id: "",
      currentPage: 1,
      total: 0,
      source: "",
      pay_type: "",
      bill_type: "",
      bill_type_list: [],
      wx_bill_type: [
        {
          name: "ALL",
          value: "当日所有的订单",
        },
        {
          name: "SUCCESS",
          value: "当日成功支付的订单",
        },
        {
          name: "REFUND",
          value: "当日退款订单",
        },
      ],
      ali_bill_type: [
        {
          name: "trade",
          value: "商户基于支付宝交易收单的业务账单",
        },
        {
          name: "signcustomer",
          value: "基于商户支付宝余额收入及支出等资金变动的账务账单",
        },
      ],
      dy_bill_type: [
        {
          name: "payment",
          value: "支付账单",
        },
        {
          name: "refund",
          value: "退款账单",
        },
        {
          name: "settle",
          value: "分账账单",
        },
        {
          name: "return",
          value: "退分账账单",
        },
      ],
    };
  },

  mounted() {},
  methods: {
    //生成表格
    ganareExcel() {
      // 需要导出的数据
      let exportArr = this.list

      const filterVal = [
        "transDate",
        "wxOrderNo",
        "businessOrderNo",
        "userIdentity",
        "transType",
        "transStatus",
        "totalAmount",
        "refundAmount",
        "refundType",
        "refundStatus",
        "businessName",
        "fee",
      ];
      exportArr = exportArr.map((item) => filterVal.map((v) => item[v]));
      // 自定义下载的header，注意是数组中的数组哦
      const Header = [
        [
          "交易时间",
          "微信订单号",
          "商户订单号",
          "用户标识(手机号)",
          "交易类型",
          "交易状态",
          "总金额",
          "退款金额",
          "退款类型",
          "退款状态",
          "商品名称",
          "手续费",
        ],
      ];

      // 将JS数据数组转换为工作表。
      const headerWs = XLSX.utils.aoa_to_sheet(Header);
      const ws = XLSX.utils.sheet_add_json(headerWs, exportArr, {
        skipHeader: true,
        origin: "A2",
      });
      //设置表格的宽度
      ws["!cols"] = [
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 150,
        },
        {
          wpx: 150,
        },
      ];
  
      /* 新建空的工作表 */
      const wb = XLSX.utils.book_new();

      // 可以自定义下载之后的sheetname
      XLSX.utils.book_append_sheet(wb, ws, "sheetName");

      /* 生成xlsx文件 */
      let name = `微信账单${this.time}.xlsx`
      XLSX.writeFile(wb,name );
    },
    
    payTypeChange(v) {
      this.bill_type_list = this[v + "_bill_type"];
      this.bill_type = "";
      v == "wx" && (this.time_mode = "day");
    },
    search(e) {
      this.currentPage = 1;
      this.list = [];
      this.getList();
    },
    fyChange(e) {
      this.currentPage = e;
      this.getList();
    },
    getList() {
      let mode,
        time = this.time_mode == "day" ? this.dayTime : this.monthTime;
      if (this.time_mode == "day") {
        mode = this.pay_type == "ali" ? "yyyy-MM-dd" : "yyyyMMdd";
      } else {
        mode = this.pay_type == "ali" ? "yyyy-MM" : "yyyyMM";
      }
      let date = new Date(time).Format(mode);
      this.time = date
      if(this.source==""){
        this.$message('请选择渠道')
        return
      }
      if(this.pay_type==""){
        this.$message('请选择支付类型')
        return
      }
      if(this.bill_type==""){
        this.$message('请选择账单类型')
        return
      }
      if(time==""){
        this.$message('请选择时间')
        return
      }
      let data = {
        currentPage: this.currentPage,
        pageSize: 10,
        source: this.source,
        pay_type: this.pay_type,
        bill_type: this.bill_type,
        bill_date: date,
      };
      this.$get("/user/pay/downloadBill", data).then((res) => {
        let result = res.data.data;
        if (res.data.code == 0) {
          if (this.pay_type == "ali") {
            if (result.msg == "Success") {
              location.href = result.billDownloadUrl;
            }
          } else if (this.pay_type == "wx") {
            this.list = res.data.data;
          } else if (this.pay_type == "dy") {
            window.open(result, "_blank");
          }
        } else {
          this.$message(res.data.message);
        }
      });
    },
  },
};
</script>
      
    <style lang="less" scoped>
</style>